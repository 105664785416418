@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
/* @import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap'); */

/*** Bring in all fonts below, but have to come through site and change everything: */


:root{

  /************ Original Color Pallette **************/

  --primary-white: #ffffff;
  --primary-light-grey: #f4f4f4;
  --primary-grey: #ccd1d9;
  --primary-dark-grey: #666666;
  --dark-mode-green: #f9ab00;
  --primary-dark: rgb(52, 58, 64);
  --primary-red: #ef4035;
  --primary-hover-red: #f33501;
  --primary-black: #000000;
  --primary-transparent-black: rgba(0,0,0,0.7);
  --primary-green: green;

  /************ Dark Mode Color Pallette **************/

  --background-black: #121212;
  --navbar-dark-grey: #181818;
  --body-light-grey: #404040;
  --body-dark-grey: #282828;
  --primary-text-white: #ffffff;
  --secondary-text-white: #B3B3B3;


  --dark-mode-red: #CF6679;
  --dark-mode-blue: #3700B3;
  --dark-mode-light-blue: #6200EE;
  --dark-mode-purple: #BB86FC;
  --dark-mode-green: #018786;
  --dark-mode-turquoise: #03DAC6;

  --dark-mode-extended-yellow: #FF991F;
  --dark-mode-extended-blue: #00A3BF;
  --dark-mode-extended-blue-light: #B3F5FF;

 
}

body{
  font-family: "Roboto Condensed", sans-serif !important;
  background-color: #000000;
  background-color: var(--primary-black);
}

/********************************* NAVBAR *********************************/

nav {
  z-index: 2;
  background: #181818;
  background: var(--navbar-dark-grey);
  /* opacity: 100%; */
}

.logo{
  /* width: 6rem;  */
  width: 100px; 
}

/****** MENU LINKS ******/

nav a.nav-link{
  font-size: .8rem;
  letter-spacing: 0.0375rem;
  cursor: pointer;
}

nav ul li {
  text-transform: uppercase;
}

.navbar-nav > li > a {
  margin-right: 0.75rem;
  margin-left: 0.75rem;
  color: #B3B3B3 !important;
  color: var(--secondary-text-white) !important;
  border-top: 0.1875rem solid var(var(--navbar-dark-grey));
  font-size: 17px;
  font-weight: 500;
} 

.navbar-brand{
  max-width: 17.25rem;
  padding: 0 !important;
  height: 6rem !important;
}

@media(max-width: 768px) {
  .navbar-brand {
    width: 7rem;
  }
}

.navbar-nav > li > a.active, .navbar-nav > li > a:focus {
  /* border-top: 0.1875rem solid var(--secondary-text-white); */
  color: #018786 !important;
  color: var(--dark-mode-green) !important;
}

.navbar-nav > li > a:hover{
  /* border-top: 0.1875rem solid var(--secondary-text-white); */
  color: #018786 !important;
  color: var(--dark-mode-green) !important;
}

.navbar-brand:hover {
  cursor: pointer;
}


/****** RESUME a LINK ******/

.resume {
  /* padding-bottom: .5rem;
  padding-left: .5rem; */
}

.resume-a {
  text-decoration: none;
  color: #B3B3B3;
  color: var(--secondary-text-white);
  border: 2px solid;
  border-color: #B3B3B3;
  border-color: var(--secondary-text-white);
  border-radius: 5px;
  padding: .5rem;
  margin-right: 1.5rem;
  font-size: 17px;
  font-weight: 500;
}

.resume-a:hover {
  text-decoration: none;
  color: #181818;
  color: var(--navbar-dark-grey);
  border-color: #B3B3B3;
  border-color: var(--secondary-text-white);
  background-color: #B3B3B3;
  background-color: var(--secondary-text-white);
  font-size: 17px;
  font-weight: 600;
}
 
/********************************* HEADER *********************************/

.header-wrapper {
  position: relative;
  background: #121212;
  background: var(--background-black);
  background: url(/static/media/DarkMountainRange.22dc29d1.jpg) no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
}

.main-info {
  display: flex;
  padding-top: 10rem;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 1;
}

.main-info h1 {
  color: #018786;
  color: var(--dark-mode-green);
  text-transform: uppercase;
}

.photo-wrap-header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
  margin-top: 5px;
}

.header-socials {
  margin-top: 10px;
}

.header-wrapper a {
  color: #018786;
  color: var(--dark-mode-green);
  z-index: 2;
  text-decoration: none;
  cursor: pointer;
}

.header-wrapper a:hover {
  color: #03DAC6;
  color: var(--dark-mode-turquoise);
}


/****** TYPED TEXT ******/

.typed-text {
  font-size: 2rem;
  color: #018786;
  color: var(--dark-mode-green);
}

@media(max-width: 768px) {
  .main-info h1 {
    font-size: 1.7rem !important;
  }
  .typed-text {
    font-size: 1.3rem !important;
  }
}

/****** HEADER MAIN OFFER BUTTON ******/

.btn-main-offer {
  border: 1px solid #018786;
  border: 1px solid var(--dark-mode-green);
  text-transform: uppercase;
  border-radius: 5px;
  padding: 0.625rem 0.75rem 0.75rem 0.75rem;
  color: #018786;
  color: var(--dark-mode-green);
  margin: 2rem;
  cursor: pointer;
  z-index: 1;
}

.btn-main-offer:hover{
  text-decoration: none;
  background-color: #018786;
  background-color: var(--dark-mode-green);
  color: #121212;
  color: var(--background-black);
  transition: .2s ease-in-out;
}

/****** PARTICLES JS******/

.particles-canvas {
  position: absolute;
  height: 100vh;
  width: 100vw;
  z-index: 1;
}

@media(max-width: 768px) {
  /* .particles-canvas {
    display: none;
  } */
}

/********************************* ABOUT PAGE *********************************/

#about {
  background: black;
  padding-top: 3rem;
  padding-bottom: 2rem;
}

.photo-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-img {
  width: 17rem;
  border-radius: 50%;
  padding: .3rem;
  border: 1px solid #018786;
  border: 1px solid var(--dark-mode-green);
  z-index: 2;
}

.about-heading {
  color: #018786;
  color: var(--dark-mode-green);
  text-transform: uppercase;
}

.p-about {
  color: #B3B3B3;
  color: var(--secondary-text-white);
}

@media(max-width: 468px) {
  .profile-img {
    width: 10rem;
  }
}

/********************************* PORTFOLIO *********************************/

div#portfolio{
  padding-top: 2rem;
}
.portfolio-wrapper {
  background: #181818;
  background: var(--navbar-dark-grey);
  position: relative;
  padding-bottom: 6rem;
}

@media(min-width: 768px){
  .portfolio-wrapper{
    padding: 0rem 0;
    padding-bottom: 0rem;
  }
 }

.popupbox-content{
  text-align: center;
}

.portfolio-wrapper h1 {
  color: #018786;
  color: var(--dark-mode-green);
  margin-bottom: 0 !important;
  font-size: 2.8rem !important;
}

.portfolio-wrapper h3 {
  color: #018786;
  color: var(--dark-mode-green);
  margin-bottom: 0 !important;
  font-size: 2rem !important;
}

.portfolio-wrapper .py-5 {
  padding: 1.5rem!important;
}

#portfolio-overlap {
  margin-left: -353px;
  color: #018786;
  color: var(--dark-mode-green);
}

.portfolio-image {
  width: 14.5rem;
  height: 8.8rem;
  border: 1px solid #018786;
  border: 1px solid var(--dark-mode-green);
  padding: 0;
  border-radius: 5px;
}

.portfolio-image-box {
  position: relative;
  margin: 1rem;
}

/****** OVERFLOW BOX ******/

.overflow {
  position: absolute;
  opacity: 0;
  background: #000000;
  background: var(--primary-black);
  width: 14.5rem;
  height: 8.8rem;
  top: 0;
  border: 1px solid #03DAC6;
  border: 1px solid var(--dark-mode-turquoise);
  cursor: pointer;
  border-radius: 5px;
}

.portfolio-image-box:hover .overflow {
  opacity: 60%;
  transition: 0.3s ease-in-out;
}

.portfolio-image-box:hover .portfolio-image {
  border: 1px solid #03DAC6;
  border: 1px solid var(--dark-mode-turquoise);
 }

.portfolio-icon {
  position: absolute;
  opacity: 0;
  margin-left: -8rem;
  margin-top: 3.6rem;
  color: #03DAC6;
  color: var(--dark-mode-turquoise);
  font-size: 1.5rem;
}

.portfolio-image-box:hover .portfolio-icon {
  opacity: .7;
  transition: 0.3s ease-in-out;
  color: #03DAC6;
  color: var(--dark-mode-turquoise);
  cursor: pointer;
}

.portfolio-icon {
  position: absolute;
  opacity: 0;
  margin-left: -8rem;
  margin-top: 3.6rem;
  color: #018786;
  color: var(--dark-mode-green);
  font-size: 1.5rem;
 }

 .portfolio-image-box:hover .portfolio-icon{
  opacity: .7;
  transition: 0.3s ease-in-out;
  cursor: pointer;
 }

 @media(min-width: 780px){
  .portfolio-wrapper h1{
    font-size: 3.2rem
  }

  #portfolio-overlap{
      margin-left: -452px;
  }
  
 }

 @media(max-width: 480px){
  .portfolio-wrapper h1{
    font-size: 1.8rem
  }

  #portfolio-overlap{
      margin-left: -255px;
  }
  
 }


/******************** POPUP ********************/

.popupbox-wrapper{
  margin-top: 4rem;
  background-color: #121212 !important;
  background-color: var(--background-black) !important;
}

.popupbox-titleBar{
  padding-top: 1px !important;
  padding-bottom: 1px !important;
  background-color: #282828;
  background-color: var(--body-dark-grey);
  color: #018786;
  color: var(--dark-mode-green);
}

.popupbox-btn--close{
  top: 4px !important;
}

.popupbox-content{
  padding: .6rem 1rem !important;
  background-color: #282828;
  background-color: var(--body-dark-grey);
}

.portfolio-image-popupbox {
  width: 45rem;
  padding: 0.5rem; 
  border: .025rem solid #018786; 
  border: .025rem solid var(--dark-mode-green);
  border-radius: .25rem;
}

.popupbox-titleBar span{
  font-weight: 580;
  font-size: 1.5rem;
}

.popupbox p{
  color: #018786;
  color: var(--dark-mode-green);
  margin: .5rem;
}

.popup-desc{
  font-size: .88rem;
  width: 45rem;
  margin: .25rem 0;
  margin-bottom: .3rem;
}


.hyper-link {
  cursor: pointer;
  color: #018786;
  color: var(--dark-mode-green);
  margin: 0 1rem;
  font-size: 1rem;
}

.hyper-link:hover{
  text-decoration: none;
  transition: 0.3s ease-in-out;
  color: #018786;
  color: var(--dark-mode-green);
}

.popup-icon:hover{
  text-decoration: none;
  transition: 0.3s ease-in-out;
  color: #018786;
  color: var(--dark-mode-green);
}

.popup-icons {
  display: flex;
  justify-content: center;
}

.popup-icon {
  margin-right: .25rem;
  color: #018786;
  color: var(--dark-mode-green);
}

.popup-links span{
  line-height: 80%;
}

/**** WIDTH ****/

@media(max-width: 1050px){

  .portfolio-image-popupbox{
    width: 90%;
  }

  .popup-desc{
    width: 100%;
    font-size: .75rem;
  }
}

@media(max-width: 768px){

  .portfolio-image-popupbox{
    width: 100%;
  }

  .popup-desc{
    font-size: .61rem;
    width: 100%;
    margin: .25rem 0;
  }

  .popup-links{
    font-size: .8rem;
  }
}

@media(max-width: 550px){
  .popup-links{
    font-size: .7rem;
  }

}


/**** HEIGHT ****/

@media(max-height: 640px){
  .popupbox-wrapper{
    width: 60%;
  }

  .popupbox-content{
    text-align: center;
  }


  .portfolio-image-popupbox{
    width: 60%;
  }

  .popup-desc {
   width: auto;
  }

  .popup-links{
    font-size: .7rem;
  }
}

@media(max-height: 570px){

  .portfolio-image-popupbox{
    width: 40%;
  }
}


/***************************** FILMMAKER COMPONENT *********************************/

.filmmaker-wrapper {
  background: #181818;
  background: var(--navbar-dark-grey);
  background-size: cover;
  background-position: center;
  padding-bottom: 2rem;
}

.filmmaker-wrapper h3 {
  color: #018786;
  color: var(--dark-mode-green);
  padding: 1rem 0;
  text-transform: uppercase;
  margin: 0rem !important;
}


/********************************* SKILLS *********************************/

.skills {
  /* background-image: url(water.jpg); */
  background-size: cover;
  background-color: #282828;
  background-color: var(--body-dark-grey);
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 1rem;
  margin-bottom: 0;
  width: 100%;
}

.skills h1 {
  color: #018786;
  color: var(--dark-mode-green);
  padding: 1rem 0;
  text-transform: uppercase;
}

.skills-content {
  text-align: center;
  max-width: 18rem;
  margin: 0 auto 2rem auto;
  background: rgba(0,0,0,0.7);
  background: var(--primary-transparent-black)
}


.myCarousel {
  color: #018786;
  color: var(--dark-mode-green);
  background: transparent;
  margin-top: -6%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 55%;
  padding-left: 5%;
  padding-right: 5%;
  height: 9rem;
}

.myCarousel h3 {
  color: #018786;
  color: var(--dark-mode-green);
  font-weight: 900;
  text-transform: uppercase;
}


.myCarousel p {
  color: #ccd1d9;
  color: var(--primary-grey);
} 


.carousel .slide img {
  width: 10rem !important;
  border-radius: 50%;
}

.carousel .slide {
  background: transparent !important;
  height: 100% !important;
  padding-top: 2rem;
}

.carousel svg {
  font-size: 5rem;
}

.carousel-root {
  margin: auto !important;
  margin-top: 3% !important;
} 


/********************************* LINKS PAGE *********************************/

.links {
  text-align: center;
  padding-bottom: 0rem;
  background: #282828;
  background: var(--body-dark-grey);
  padding-top: 0rem;
  /* background: url(water.jpg); */
  background-size: cover;
  background-position: center;
  /* height: 165vh; */

}

.links h1 {
  color: #018786;
  color: var(--dark-mode-green);
  text-transform: uppercase;
}

.links h3 {
  color: #018786;
  color: var(--dark-mode-green);
  text-transform: uppercase;
}

.links h5 {
  color: #018786;
  color: var(--dark-mode-green);
  text-transform: uppercase;
}

.links p {
  color: #018786;
  color: var(--dark-mode-green);
  text-transform: uppercase;
}

.links .circle {
  position: relative;
  margin: 0.375rem auto;
  background: #018786;
  background: var(--dark-mode-green);
  border-radius: 50%;
  width: 3.125rem;
  height: 3.125rem;
}

.links .icon {
  font-size: 1.4rem;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.links .box {
  height: 16rem;
  border-bottom: 0.3125rem solid #018786;
  border-bottom: 0.3125rem solid var(--dark-mode-green);
  padding: 0.625rem;
  margin-bottom: 1.875rem;
  background: transparent;
  transition: 0.3s ease-in-out;
  border: #018786 solid 1px;
  border: var(--dark-mode-green) solid 1px;
  border-radius: 5px;
}

.links .box:hover {
  background: #018786;
  background: var(--dark-mode-green);
  border-bottom: 0.3125rem solid  #282828;
  border-bottom: 0.3125rem solid  var(--body-dark-grey);
  cursor: pointer;
  /* opacity: 75%; */
}

.links .box:hover .icon {
  color: #018786;
  color: var(--dark-mode-green);
}

.links .box:hover .circle {
  background: #282828;
  background: var(--body-dark-grey);
}

.links .box:hover h3 {
  color: #282828;
  color: var(--body-dark-grey);
  font-weight: bold !important;
}

.links .box:hover p {
  color: rgba(0,0,0,0.7);
  color: var(--primary-transparent-black);
}

.links-div {
  padding-top: 4rem;
}

.links a {
  text-decoration: none !important;
  color: inherit;
}

.links a:hover {
  text-decoration: none !important;
  color: inherit;
}


/******** LINK BUTTONS **********/

button.btn-links {
  background: #282828;
  background: var(--body-dark-grey);
  height: 100px;
  width: 105px;
  font-size: large;
  border: 1px solid #03DAC6;
  border: 1px solid var(--dark-mode-turquoise);
  text-transform: uppercase;
  border-radius: 0;
  padding: 0.625rem 0.75rem 0.75rem 0.75rem;
  color: #B3B3B3;
  color: var(--secondary-text-white);
  margin-top: .75rem;
  cursor: pointer;
}

.btn-links:hover{
  text-decoration: none;
  background-color: #404040;
  background-color: var(--body-light-grey);
  color: #B3B3B3;
  color: var(--secondary-text-white);
  transition: .2s ease-in-out;
}

/******** LINKS SKILLS ICONS **********/

.skills-icons {
  margin: 3rem;
}

/****** PARTICLES JS******/

.particles-space {
  position: absolute;
  height: 65vh;
  width: 100vw;
  /* z-index: 1; */
}

@media(max-width: 768px) {
  .particles-space {
    /* display: none; */
  }
}

/********************************* CONTACT *********************************/

.contact {
  background: #121212;
  background: var(--background-black);
  /* background: url(underwater.jpg); */
  padding: 3rem 0;
  background-size: cover;
  background-position: center;
}

.contact h1 {
  color: #018786;
  color: var(--dark-mode-green);
  padding: 1.3rem 0;
  text-transform: uppercase;
}

.contact p {
  color: #018786;
  color: var(--dark-mode-green);
  font-size: 1.2rem;
}

/****** INPUTS  ******/

.contact input, .contact textarea {
  width: 100%;
  box-sizing: border-box;
  background: none;
  resize: none;
  border: 0;
  border-bottom: 0.125rem solid #666666;
  border-bottom: 0.125rem solid var(--primary-dark-grey);
  border-radius: 0;
  color: #018786;
  color: var(--dark-mode-green);
  outline: 0 !important;
  box-shadow: none !important;
  margin-bottom: 1rem;
  padding-left: 0 !important;
}

.contact .container {
  padding: 4rem 0;
}

.contact input:focus, .contact textarea:focus {
  background: transparent;
  color: #018786;
  color: var(--dark-mode-green);
  border-bottom: 0.125rem solid #666666;
  border-bottom: 0.125rem solid var(--primary-dark-grey);
}

.contact textarea {
  height: 12.2rem !important;
}

button.btn-main-offer.contact-btn {
  margin: 1rem 0 0 0;
  width: 100%;
  background: none;
  border-color: #018786;
  border-color: var(--dark-mode-green);
  color: #018786;
  color: var(--dark-mode-green);
}

button.btn-main-offer.contact-btn:hover{
  text-decoration: none;
  background-color: #018786;
  background-color: var(--dark-mode-green);
  color: #121212;
  color: var(--background-black);
  transition: .2s ease-in-out;
}

.line {
  position: relative;
  top: -0.8rem;
  width: 0;
  height: .0125rem;
  background: #018786;
  background: var(--dark-mode-green);
  display: inline-block;
}

input:focus+.line, textarea:focus+.line {
  width: 100%;
  transition: width 0.5s ease-in-out;
}

input.form-control {
  margin: -0.3rem 0;
}

textarea.form-control {
  margin: -0.3rem 0;
}

.error-message {
  color: #ffffff;
  color: var(--primary-text-white);
  background: #018786;
  background: var(--dark-mode-green);
  text-align: left;
}

.success-message {
  font-weight: 900;
  color: #B3B3B3;
  color: var(--secondary-text-white);
}

/****** MEDIA  ******/

@media(max-width: 768px) {
  .contact {
    overflow-x: hidden;
    background-size: 100rem;
    background-position: center;
  }

  .contact .container {
    padding: 0 1rem;
  }

  .contact p {
    padding: 0 0.4rem;
  }
}

/****** PARTICLES JS******/

.particles-bubbles {
  position: absolute;
  height: 100vh ;
  width: 100vw;
  /* z-index: 1; */
}

@media(max-width: 768px) {
  .particles-bubbles {
    /* display: none; */
  }
}

/********************************* FOOTER *********************************/

.footer {
  background: #000000;
  background: var(--primary-black);
  color: #B3B3B3;
  color: var(--secondary-text-white);
  padding: 3rem;
}

.footer a {
  text-decoration: none;
  color: #018786;
  color: var(--dark-mode-green);
}

.footer a:hover {
  text-decoration: none;
  color: #03DAC6;
  color: var(--dark-mode-turquoise);
  cursor: pointer;
}

.footer p {
  margin-bottom: 0;
  color: #666666;
  color: var(--primary-dark-grey);
}

.logo-footer{
  width: 5rem;
}

.footer-nav{
  color: #666666 !important;
  color: var(--primary-dark-grey) !important;
}

.social-links {
  margin: .5rem;
}



/********************************* EXPERIENCE *********************************/

.experience {
  padding: 0.7rem;
  background: #282828;
  background: var(--body-dark-grey);
}

.experience-wrapper {
  position: relative;
  padding: 3.125rem 0;
  overflow: hidden;
}

.experience h1 {
  text-transform: uppercase;
  color: #CF6679;
  color: var(--dark-mode-red);
}

.experience-wrapper::before {
  position: absolute;
  content: "";
  top: 0;
  left: 50%;
  margin-left: -0.0625rem;
  width: 0.125rem;
  height: 100%;
  background: #181818;
  background: var(--navbar-dark-grey);
  z-index: 1;
}

.timeline-block {
  width: calc(50% + 0.5rem);
  display: flex;
  justify-content: space-between;
  clear: both;
}

.timeline-block-right {
  float: right;
}

.timeline-block-left {
  float: left;
  direction: rtl;
}

.marker {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  border: 0.3125rem solid #181818;
  border: 0.3125rem solid var(--navbar-dark-grey);
  background: #CF6679;
  background: var(--dark-mode-red);
  margin-top: 00.625rem;
  z-index: 3;
}

.timeline-content {
  width: 95%;
  padding: 0 0.9375rem;
  color: #666666;
  color: var(--primary-dark-grey);
  direction: ltr;
}

.timeline-content h3 {
  margin-top: 0.3125rem;
  color: #CF6679;
  color: var(--dark-mode-red);
  font-weight: 900;
}

.timeline-content h4 {
  margin-top: 0.3125rem;
  color: #018786;
  color: var(--dark-mode-green);
  font-weight: 900;
}

.timeline-content h6 {
  margin-top: 0.3125rem;
  font-weight: 900;
}

.timeline-content p {
  margin-top: 0.3125rem;
  font-weight: 900;
}

/****** MEDIA ******/

@media(max-width: 768px) {
  .experience .container {
    padding-left: 0;
  }
  .experience-wrapper::before{
    left: 0.5rem;
    margin-bottom: 1.875rem;
  }
  .timeline-block-right {
    float: none;
    width: 100%;   
  }
  .timeline-block-left {
    float: none; 
    direction: ltr;  
    width: 100%;   
  }
}


